/**
 * @link https://www.npmjs.com/package/smooth-scroll
 */

import ScrollMagic from 'scrollmagic'
import SidebarActive from './scroll/SidebarActive'
import './scroll/smoothScroll'

const scenes = {
  about: document.getElementById('about'),
  destination: document.getElementById('home-destination'),
  years: document.querySelectorAll('.year-posts')
}

window.addEventListener('DOMContentLoaded', () => {
  new SidebarActive()
})

if (scenes.years) {
  const controller = new ScrollMagic.Controller()
  const headers = document.querySelectorAll('.year-posts__year')

  headers.forEach(header => {
    header.style.transform = 'translate(-50%, 30%)'
  })

  scenes.years.forEach(scene => {
    const header = scene.querySelector('.year-posts__year')

    let translateY = 40
    let step = 0.75

    new ScrollMagic.Scene()
      .triggerElement(scene)
      .addTo(controller)
      .offset(-250)
      .duration('100%')
      .on('progress', ({ scrollDirection }) => {
        if (scrollDirection == 'FORWARD' && translateY <= 40 && translateY >= 1) translateY = translateY - step
        if (scrollDirection == 'REVERSE' && translateY + 1 <= 40) translateY = translateY + step

        header.style.transform = `translate(-50%, ${translateY}%)`
      })
  })
}

if (scenes.about || scenes.destination) {
  const controller = new ScrollMagic.Controller()

  new ScrollMagic.Scene()
    .triggerElement('#about')
    .addTo(controller)
    .on('enter', () => {
      scenes.about.classList.add('is-active')
    })
}
