import SiteHeader from './siteHeader'
import MainNav from './navigation'
import FloatLabels from './forms/float-labels'
import MobileMenu from './MobileMenu'
import Accordion from './Accordion'
import ShowMore from './ShowMore'
import MicroModal from 'micromodal'
import IsSticky from './isSticky'
import PostsGrid from './PostsGrid'
import SetEqualElems from './SetEqualElems'
import AOS from 'aos'

window.addEventListener('DOMContentLoaded', () => {
  new SiteHeader({
    setBodyPaddingTop: false
  })
  new FloatLabels()
  new MainNav()
  new Accordion()
  new ShowMore('testimonials-btn', '.testimonial:not(:first-of-type)')
  new IsSticky('#secondary .widget-area__inner', 100)
  new PostsGrid('.year-posts__posts-container')
  new SetEqualElems('.gallery-with-description', '.gallery-with-description__description')
  AOS.init({
    duration: 1000,
    once: true
  })

  document.addEventListener('contextmenu', e => e.preventDefault())

  MicroModal.init({
    awaitCloseAnimation: true
  })

  new MobileMenu()
})
