import ScrollMagic from 'scrollmagic'

class SidebarActive {
  constructor() {
    this.sections = document.querySelectorAll('.single-safari .safari-sections__section')
    this.links = document.querySelectorAll(`.widgets-sections a`)
    this.setActiveClass()
    this.scroll()
  }

  scroll() {
    if (this.sections) {
      const controller = new ScrollMagic.Controller()
      const headerHeight = document.getElementById('masthead').clientHeight

      this.sections.forEach(scene => {
        new ScrollMagic.Scene()
          .triggerElement(scene)
          .addTo(controller)
          .offset(headerHeight + 75)
          .duration(scene.clientHeight)
          .on('enter', () => {
            this.clearActiveClasses()

            const sceneId = scene.getAttribute('id')
            const link = document.querySelector(`.widgets-sections a[href="#${sceneId}"`)

            link.classList.add('active')
          })

          .on('leave', () => this.clearActiveClasses())
      })
    }
  }

  clearActiveClasses() {
    const links = this.links
    links.forEach(link => link.classList.remove('active'))
  }

  setActiveClass() {
    this.clearActiveClasses()
    this.links.forEach(link =>
      link.addEventListener('click', () => {
        link.classList.add('active')
      })
    )
  }
}

export default SidebarActive
