export default class SiteHeader {
  constructor(
    defaultSettings = {
      setBodyPaddingTop: true,
      stickyHeader: true
    }
  ) {
    this.currentScrollPosition = 0
    this.defaultSettings = defaultSettings
    this.siteHeader = document.getElementById('masthead')
    this.init()
  }

  init() {
    this.defaultSettings.setBodyPaddingTop === true && this.setBodyPaddingTop()
    this.defaultSettings.stickyHeader === false || this.stickyHeader()
  }

  setBodyPaddingTop() {
    let $body = document.querySelector('body'),
      siteHeaderHeight = this.siteHeader.clientHeight

    $body.style.paddingTop = `${siteHeaderHeight}px`
  }

  stickyHeader() {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop <= 0) {
        this.siteHeader.classList.remove('sticky')
        return
      }

      if (this.currentScrollPosition > document.documentElement.scrollTop) {
        this.siteHeader.classList.add('sticky')
        this.siteHeader.classList.remove('sticky-exit')
        document.body.classList.remove('sticky-exit')
      } else {
        this.siteHeader.classList.add('sticky-exit')
        document.body.classList.add('sticky-exit')
      }

      this.currentScrollPosition = document.documentElement.scrollTop
    })
  }
}
