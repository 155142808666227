class PostsGrid {
  constructor(postsContainer) {
    this.postsContainer = document.querySelectorAll(postsContainer)

    if (this.postsContainer.length) {
      this.init()
    }
  }

  init() {
    this.postsContainer.forEach(postContainer => {
      const containerWidth = postContainer.offsetWidth - Math.abs(parseFloat(getComputedStyle(postContainer).marginLeft))
      const posts = postContainer.querySelectorAll('.hentry')

      posts.forEach(post => {
        const postWidth = post.clientWidth

        if (postWidth == containerWidth) post.classList.add('full-width')

        if (postWidth + Math.abs(parseFloat(getComputedStyle(postContainer).marginLeft)) / 2 == containerWidth / 2) post.classList.add('half-width')
      })
    })
  }
}

export default PostsGrid
