import $ from 'jquery'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'
import SetEqualElems from '../SetEqualElems'

const slider = $('#destinations-slider')

class DestinationSlider {
  constructor() {
    this.container = document.getElementById('home-destination')
    this.destinationPartials = this.container.querySelectorAll('.destination-partial')
    this.goToSlide()
    this.setFirst()
    new SetEqualElems('.home-destination .destination-partial-container', '.home-destination .destination-partial', true)
  }

  setFirst() {
    this.setActive(this.getNextImage(0))
  }

  goToSlide() {
    slider[0].querySelectorAll('.slick-slide').forEach(slide => {
      slide.addEventListener('click', () => {
        const index = slide.getAttribute('data-slick-index')
        slider.slick('slickGoTo', index)
      })
    })
  }

  removeActive() {
    this.destinationPartials.forEach(image => image.classList.remove('active'))
  }

  setActive(index) {
    this.removeActive()
    this.destinationPartials.forEach(partial => {
      if (partial.getAttribute('data-index') == index) partial.classList.add('active')
    })
  }

  getNextImage(currentSlide) {
    return currentSlide

    // const offset = 2;
    // const slides = [
    //   ...document.querySelectorAll (
    //     '#destinations-slider .slick-slide:not(.slick-cloned) .single-slide'
    //   ),
    // ];

    // if (slides.length - offset - currentSlide == 0) {
    //   return slides[0].getAttribute ('data-index');
    // }

    // if (slides[currentSlide + offset]) {
    //   return slides[currentSlide + offset].getAttribute ('data-index');
    // } else {
    //   return this.getNextImage (slides.length - offset - currentSlide);
    // }
  }
}

if (slider.length) {
  slider.slick({
    autoplay: true,
    arrows: false,
    slidesToShow: 2,
    speed: 1000,
    autoplaySpeed: 4000
  })

  const destinationsSlider = new DestinationSlider()
  slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    destinationsSlider.setActive(destinationsSlider.getNextImage(nextSlide))
  })
}
