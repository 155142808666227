import $ from 'jquery'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

const slider = $('#single-destination-slider')

slider
  .slick({
    autoplay: true,
    slidesToShow: 1,
    speed: 1000,
    autoplaySpeed: 4000,
    prevArrow: $('.single-destination-slider .slider-nav--prev'),
    nextArrow: $('.single-destination-slider .slider-nav--next')
  })
  .on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    if (nextSlide == 0) {
      const opt = false
      var cls = 'slick-current slick-active' + (opt ? ' slick-center' : '')

      setTimeout(function() {
        $('[data-slick-index="' + slick.$slides.length + '"]')
          .addClass(cls)
          .siblings()
          .removeClass(cls)
        for (var i = slick.options.slidesToShow - 1; i >= 0; i--) {
          $('[data-slick-index="' + i + '"]').addClass(cls)
        }
      }, 10)
    }
  })
