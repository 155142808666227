import $ from 'jquery'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

const slider = $('.side-by-side-slider')

if (slider) {
  slider.slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })
}
