class IsSticky {
  constructor(container, offset) {
    this._container = document.querySelector(container)
    this._offset = offset
    this.init()
  }

  init() {
    if (this._container) {
      const containerHeight = this._container.offsetHeight
      const windowHeight = window.innerHeight

      if (containerHeight + this._offset > windowHeight) {
        this._container.style.position = 'static'
      }
    }
  }
}

export default IsSticky
