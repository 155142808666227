class SetEqualElems {
  constructor(containers, elems, setForContainer) {
    this._setForContainer = setForContainer
    this._containers = document.querySelectorAll(containers)
    this._elems = elems

    if (this._containers.length) {
      this.setEqualHeight()
    }
  }

  setEqualHeight() {
    this._containers.forEach(container => {
      const elems = container.querySelectorAll(this._elems)
      if (elems.length) {
        let height = elems[0].clientHeight

        elems.forEach(elem => {
          const elemHeight = elem.clientHeight
          if (elemHeight > height) {
            height = elem.clientHeight
          }
        })

        if (this._setForContainer) {
          this._containers.forEach(container => (container.style.height = `${height}px`))
        } else elems.forEach(elem => (elem.style.height = `${height}px`))
      }
    })
  }
}

export default SetEqualElems
