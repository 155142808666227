import $ from 'jquery'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

const slider = $('#accomodation-slider')

if (slider.length) {
  const title = document.querySelector('.safari-sections__title')
  if (title) {
    slider[0].style.width = `${title.clientWidth}px`
  }

  const adaptiveHeight = slider[0].getAttribute('data-adaptive-height')

  slider.slick({
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    fade: true,
    adaptiveHeight: adaptiveHeight == 1 ? true : false,
    nextArrow: $('.slider-arrow--next'),
    prevArrow: $('.slider-arrow--prev')
  })
}
