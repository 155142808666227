import $ from 'jquery'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

const slider = $('#hero-slider')

if (slider) {
  slider.slick({
    dots: true,
    arrows: false,
    autoplay: true,
    appendDots: $('.slick-dots-container'),
    pauseOnDotsHover: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    speed: 2000,
    fade: true
  })
}
