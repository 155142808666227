import CoreAnimation from './animations/CoreAnimations'

class Accordion {
  constructor() {
    this.accordions = document.querySelectorAll('.accordion')
    this.activeClass = 'accordion__single--active'
    this.init()
  }

  init() {
    if (this.accordions.length) {
      this.accordions.forEach(accordion => {
        this.singles = accordion.querySelectorAll('.accordion__single')
        this.singles.forEach(single => {
          const btn = single.querySelector('button[data-toggle="collapse"]')

          btn.addEventListener('click', () => {
            const content = single.querySelector('.accordion__content')
            this.hide(content)
            single.classList.toggle(this.activeClass)
            CoreAnimation.slideToggle(content)
          })
        })
      })
    }
  }

  hide(activePanel) {
    this.singles.forEach(single => {
      const content = single.querySelector('.accordion__content')

      if (single.classList.contains(this.activeClass) && activePanel !== content) {
        single.classList.remove(this.activeClass)
        CoreAnimation.slideUp(content)
      }
    })
  }
}

export default Accordion
