import CoreAnimation from './animations/CoreAnimations'
import SmoothScroll from 'smooth-scroll'

class ShowMore {
  constructor(buttonId, container) {
    this._button = document.getElementById(buttonId)
    this._containers = document.querySelectorAll(container)
    this.option = 'more'
    this.smoothScroll = new SmoothScroll()
    this.init()
  }

  init() {
    if (this.isElemsExist()) {
      this.section = this._button.closest(`div[id], section[id]`)

      this._containers.forEach(container => {
        CoreAnimation.slideUp(container)
      })

      this._button.addEventListener('click', () => {
        this._containers.forEach(container => {
          this.setButtonText(this.option)
          CoreAnimation.slideToggle(container)
        })
      })
    }
  }

  isElemsExist() {
    if (!this._button) {
      console.warn('Button not exist')
      return false
    }
    if (!this._containers) {
      console.warn('Containers not exist')
      return false
    }

    return true
  }

  setButtonText(option) {
    if (this.option == 'more') {
      this.option = 'less'
      this._button.innerText = this._button.getAttribute('data-show-less')
    }

    if (option == 'less') {
      this.option = 'more'
      this._button.innerText = this._button.getAttribute('data-show-more')
      if (this.section) {
        this.smoothScroll.animateScroll(this.section, null, {
          ignore: '[data-vc-container]',
          header: '#masthead',
          offset: 40,
          speed: 1000
        })
      }
    }
  }
}

export default ShowMore
